<template>
    <div class="detail-tab-detail">
        商品详情（待对接修改）
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>

</style>